import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/roman/dev/ng/notegraph.com/src/components/layout-blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hello, my name is Roman and I live and work in Toronto, Canada. You can learn more about this project on the `}<a parentName="p" {...{
        "href": "/blog"
      }}>{`blog`}</a>{`.`}</p>
    <p>{`At the moment, the project is on pause since I don't have time and attention to make any meaningful progress.`}</p>
    <p>{`Have any interesting ideas? send me email: roman`}<inlineCode parentName="p">{`@`}</inlineCode>{`notegraph.com`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      