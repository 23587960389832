import React from "react";
import PropTypes from "prop-types";
import { Container, Flex, Box } from "theme-ui";

import Header from "./header";
import Footer from "./footer";

const Layout = ({ children }) => {
  return (
    <Flex sx={{ minHeight: "100vh", flexDirection: "column" }}>
      <Header />
      <Container p={2}>{children}</Container>
      <Box sx={{ flexGrow: 1 }} />
      <Footer />
    </Flex>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
